@import './mixins.scss';
@import './vars.scss';
@import './navigation.scss';
@import './card.scss';
@import './loader.scss';
@import './admin.scss';
@import './form.scss';

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

* img {
	max-width: 100%;
	max-height: 100%;
}

::selection {
	background-color: $primary;
	color: #fff;
}

html {
	font-size: 15px;
	color: $black;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	@include bp(mini) {
		font-size: 13px;
	}
}

html, body {
	max-width: 100%;
	overflow-x: hidden;
}

body {
	font-family: $body;
	font-weight: 400;
	line-height: 1.4;
	width: 100%;
	position: relative;
	margin: 0 auto;
	letter-spacing: 0;

	main {
		position: relative;

		&.maintenance {
			section {
				min-height: 100vh;
				background-color: $primary;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.wrapper {
					width: 800px;
					max-width: 100%;
					text-align: center;
					margin: 10px auto 30px;

					.logo {
						width: 250px;
						margin: 0 auto 1rem;
					}

					h1, h2, p {
						color: #fff;
						font-weight: 400;
						margin: 0 auto 0.5rem;
					}

					h1 {
						font-size: 2rem;
						@include bp(sm) {
							font-size: 1.8rem;
						}
					}

					h2 {
						font-size: 1.4rem;
					}

					a {
						font-weight: bold;
						color: $secondary;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		a {
			color: $primary;
		}
	}
}

.info-window {
	display: none;
	
	&.active {
		display: inline-block !important;
	}
}