.create-form {
	.flex-form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1rem;
		@include bp(mini) {
			display: block;
		}

		> div {
			flex-basis: 100%;

			&.half {
				flex-basis: calc(50% - 10px);
			}

			label {
				font-weight: bold;
				font-size: 0.9rem;
			}

			textarea {
				resize: vertical;
				height: 80px;
				font-family: $body;
			}
		}

		.delete-image {
			text-align: right;
		}
	}
}

.flex-buttons {
	display: flex;
	justify-content: space-between;
	@include bp(sm) {
		display: block;
	}

	&.create {
		justify-content: flex-start;
	}

	button {
		margin-right: 10px;
		@include bp(sm) {
			margin: 0 0 10px 0;
			width: 100%;
		}
		
		&.danger {
			margin-right: 0;
		}
		
		&:disabled {
			background-color: $gray;
			cursor: default;
		}
	}
}

input:not([type=radio]), textarea {
	width: 100%;
	display: block;
	margin-bottom: 1rem;
	border: 2px solid $gray;
	padding: 10px;
	border-radius: 3px;
	font-size: 0.9rem;
	outline: none;
	-webkit-appearance: none;

	&:focus {
		border-color: $primary;
	}
}

input[type=submit], button, a.cta {
	background-color: $secondary;
	color: #fff;
	padding: 10px 20px;
	text-align: center;
	border: none;
	font-size: 0.9rem;
	border-radius: 3px;
	text-decoration: none;
	margin-bottom: 1rem;
	cursor: pointer;

	&:active, &:focus {
		border: none;
		outline: none;
	}

	&:hover {
		background-color: darken($secondary, 5%);
		color: #fff;
	}

	&.danger {
		background-color: $primary;

		&:hover {
			background-color: darken($primary, 5%);
		}
	}
}

.asterisk {
	color: $primary;
}