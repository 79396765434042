main > header {
	font-weight: 700;
	padding: 15px;
	position: fixed;
	background-color: #fff;
	height: 65px;
	width: 100%;
	top: 0;
	z-index: 2;
	box-shadow: $boxShadow;

	.logo {
		height: 100%;

		a {
			display: block;
			height: 100%;
			margin: 0 auto;
			text-indent: -9999px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('/images/logo-lg.svg');
			background-position: center;
		}
	}

	ul {
		list-style: none;
		display: flex;
		margin: 0;
		padding: 0 20px 0 0;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;

		@include bp(mini) {
			display: none;
		}

		li {
			align-self: center;
			&:first-child {
				padding-right: 10px;
			}

			a {
				text-decoration: none;
				text-transform: uppercase;
				color: $gray;
				font-size: 0.8rem;
			}
		}
	}
}