.admin {
	margin-top: 65px;
	padding: 40px;
	@include bp(sm) {
		padding: 20px;
	}

	.wrapper {
		max-width: 800px;
		margin: 0 auto;

		h1 {
			margin-top: 0;
		}

		h2 {
			font-size: 1.35rem;
			margin-bottom: 0;

			+ form {
				margin-top: 1rem;
			}
		}

		h3 {
			margin-top: 0;
			color: $gray;
		}

		.filter {
			position: relative;

			input {
				&[type="text"] {
					&#filterField {
						border: 2px solid $lightGray;
						border-radius: 100px;
						padding-left: 15px;
						padding-right: 35px;
						background-image: url('/images/magnifying-glass.svg');
						background-repeat: no-repeat;
						background-size: 16px;
						background-position: calc(100% - 10px);
						position: relative;
						height: auto;
						background-color: #fff;

						&.active {
							background-image: url('/images/close.svg');
							background-size: 12px 16px;
							background-position: calc(100% - 12px);
						}
					}
				}
			}

			button {
				&#clearFilter {
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					border-radius: 100px;
					background: transparent;
				}
			}
		}
	}
}

.admin-list {
	list-style: none;
	padding: 0;
	margin: 0 0 1rem;

	li {
		margin-bottom: 10px;
		font-size: 0.9rem;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			border: 2px solid $gray;
			border-radius: 3px;
			@include bp(mini) {
				display: block;
			}

			.data {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				@include bp(sm) {
					display: block;
				}
				@include bp(mini) {
					margin-bottom: 1rem;
				}

				span {
					margin-right: 10px;
					@include bp(sm) {
						display: block;
						margin-right: 0;
					}

					&.admin-status {
						font-size: 0.8rem;
						font-weight: bold;
						color: $gray;

						.adminUser {
							color: $warning;
						}

						.superUser {
							color: $success;
						}
					}
				}
			}

			.buttons {
				a {
					&:not(:last-child) {
						margin-right: 10px;
						@include bp(sm) {
							margin-bottom: 10px;
							margin-right: 0;
						}
					}

					@include bp(sm) {
						display: block;
					}

					button {
						margin-bottom: 0;
						@include bp(sm) {
							width: 100px;
						}
						@include bp(mini) {
							width: 100%;
						}
					}
				}

				form {
					display: flex;

					div {
						margin-right: 15px;

						&:last-of-type {
							margin-right: 0;
						}

						span {
							font-weight: bold;
							color: $gray;
							font-size: 0.8rem;
						}

						input {
							&[type=radio] {
								transform: translateY(2px);
								
								&.superUser {
									accent-color: $success;
								}
				
								&.adminUser {
									accent-color: $warning;
								}
				
								&.basicUser {
									accent-color: $error;
								}
							}
						}
					}
				}
			}
		}
	}
}