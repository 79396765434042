@mixin bp($point) {
	$xl: "(min-width: 1200px)";
	$large: "(min-width: 992px)";
	$mobile: "(max-width: 991px)";
	$med: "(min-width: 576px)";
	$small: "(max-width: 575px)";
	$mini: "(max-width: 480px)";

	@if $point == xl {
		@media all and #{$xl} { @content; }
	}
	@if $point == lrg {
	  @media all and #{$large} { @content; }
	}
	@if $point == mobile {
		@media all and #{$mobile} { @content; }
	}
	@if $point == med {
		@media all and #{$med} { @content; }
	  }
	@if $point == sm {
		@media all and #{$small} { @content; }
	}
	@if $point == mini {
		@media all and #{$mini} { @content; }
	}
}