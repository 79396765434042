aside.card {
	background-color: #fff;
	width: 400px;
	height: calc(100vh - 105px);
	position: absolute;
	margin: 20px;
	padding: 1.5rem 0.5rem 1.5rem 1.5rem;
	z-index: 1;
	border-radius: 2px;
	box-shadow: $boxShadow;
	overflow: hidden;

	@include bp(med) {
		top: 65px;
	}

	@include bp(mobile) {
		width: 300px;
	}

	@include bp(sm) {
		margin: 10px;
		width: calc(100% - 20px);
		height: 320px;
		bottom: 0;
	}

	.employee {
		height: 100%;

		.employee-card {
			height: calc(100% - 21px);
			padding-right: 1rem;
			padding-bottom: 25px;
			overflow-y: scroll;
			display: block;

			&.placeholder {			
				h2 {
					font-size: 2.5rem;
					margin: 0 0 2rem;
					text-align: center;
					line-height: 1.2;
					@include bp(mobile) {
						font-size: 2.2rem;
					}
				}
		
				p {
					color: $gray;
				}
			}

			&.employee-active {
				.content {
					position: relative;

					.employee-edit {
						position: absolute;
						top: 8px;
						right: 0;
						background-color: $primary;
						color: #fff;
						padding: 4px;
						text-decoration: none;
						text-transform: uppercase;
						font-size: 0.7rem;
						font-weight: bold;
						border-radius: 2px;
			
						&:hover {
							background-color: darken($primary, 5%);
						}
					}
				}
			}

			h2 {
				font-weight: 400;
				font-size: 2rem;
				margin: 0;
			}

			h3 {
				margin: 0;
				text-transform: capitalize;
			}

			h4 {
				margin: 0 0 1.5rem;
				text-transform: uppercase;
				font-size: 0.8rem;
				color: $gray;
			}

			p {
				margin: 0 0 1rem;
				
				&.question {
					font-weight: 700;
					margin-bottom: 0;
				}

				&.answer {
					color: $gray;
				}
			}

			img {
				display: block;
				max-height: 60%;
				margin: 0 auto 1rem;
				@include bp(mobile) {
					margin: 1.5em auto 0;
					max-height: 100%;
				}
			}
		}

		button.start {
			display: block;
			margin: 2rem auto 0;
			border: 0;
			border-radius: 3px;
			color: #fff;
			background-color: $primary;
			cursor: pointer;
			font-size: 0.8rem;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 700;
			user-select: none;
			padding: 0.75rem 1.5rem;

			&:hover {
				background-color: darken($primary, 5%);
			}
		}
	}

	.card-nav {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 40px;
		padding: 8px;
		background-color: #fff;
		border-top: 1px solid $lightGray;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: #fff 0px 0px 10px 8px;
		
		button {
			border: 0;
			border-radius: 0;
			padding: 0;
			margin-bottom: 0;
			background-color: transparent;
			cursor: pointer;
			font-size: 0.8rem;
			background-size: 13px 13px;
			background-repeat: no-repeat;
			text-decoration: none;
			text-transform: uppercase;
			color: $gray;
			font-weight: 700;
			width: calc(100% / 3);
			user-select: none;

			&:hover {
				color: $primary;
			}

			&.disabled {
				cursor: default;
			}
			
			&.prev {
				background-image: url('/images/angle-left-solid.svg');
				background-position: 0 25%;
				padding-left: 18px;
				text-align: left;
				&:hover {
					background-image: url('/images/angle-left-solid-red.svg');
				}
			}

			&.next {
				background-image: url('/images/angle-right-solid.svg');
				background-position: 100% 25%;
				padding-right: 18px;
				text-align: right;
				&:hover {
					background-image: url('/images/angle-right-solid-red.svg');
				}
			}
		}
	}
}