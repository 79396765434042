$primary: #FF0033;
$secondary: #9ACCCD;
$lightGray: #e5e5e5;
$gray: #616161;
$black: #212121;
$white: #fff;

$error: #d32f2f;
$warning: #ffa726;
$success: #388e3c;

$body: 'roboto', sans-serif;

$boxShadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;