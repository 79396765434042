.spinner {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 3;
	background-color: rgba($black, 0.5);
	backdrop-filter: blur(5px);

	.loader {
		margin: 0 auto;
		width: 80px;
		text-align: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		> div {
			width: 12px;
			height: 12px;
			margin: 0 4px;
			background-color: $white;
			border-radius: 100%;
			display: inline-block;
			-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
			animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		}

		.bounce1 {
			-webkit-animation-delay: -0.32s;
			animation-delay: -0.32s;
		}

		.bounce2 {
			-webkit-animation-delay: -0.16s;
			animation-delay: -0.16s;
		}
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}